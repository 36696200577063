import React from "react";
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import SidebarRecentPosts from "../components/sidebar-recent-posts";
import SidebarAboutMe from "../components/sidebar-about-me";
import Article from "../components/article"

const RecentPosts = ({data}) => (
    <Layout page='recent-posts'>
    <SEO title="Recent posts" />
    <div class="container container-flex">
        <main>
          {data.posts.edges.map (({node}) => (
            <Article node={node} />
           ))
          }
        </main>

        <aside class="sidebar">
          <SidebarAboutMe />
          <SidebarRecentPosts />
        </aside>
      </div>
    </Layout>
);

export default RecentPosts;

export const pageQuery = graphql`
 query AllPosts {
  posts: allContentfulSimpleLife(sort: {fields: date, order: DESC}, filter: {date: {gt: "2000-01-00T00:00+02:00"}}) {
    edges {
      node {
        comment
        date
        featured
        slug
        title
        shortDescription {
          json
        }
        content {
          json
        }
        cover {
          fluid(quality: 90) {
          ...GatsbyContentfulFluid
          }
        }
      }
      next {
        slug
        title
      }
      previous {
        title
        slug
      }
    }
    totalCount
  }
}

`